import './App.css';
import { useState, useEffect } from 'react';
import Mouse from './Mouse';
import { 
  handleInitResponse, 
  addUserToUsers, 
  selectCanPaint,
  selectPaintColor,
} from './features/draw/drawSlice';
import { useDispatch, useSelector } from 'react-redux';
import Menu from './Menu';
const randomstring = require("randomstring");

function App() {
  const dispatch = useDispatch();

  const [dots, setDots] = useState([]);
  const [localDots, setLocalDots] = useState([]);
  const [loading, setLoading] = useState(undefined);
  const [rightFencePost, setRightFencePost] = useState(undefined);
  const [downFencePost, setDownFencePost] = useState(undefined);
  const [relisten, setRelisten] = useState(false);

  const canPaint = useSelector(selectCanPaint);
  const paintColor = useSelector(selectPaintColor);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  
  useEffect(() => {
    if(loading == undefined) {
      setLoading(true);
      window.io.socket.get('/api/dot', gotDotResponse);
    }
  }, []);
  
  useEffect(() => {
    console.log("on user mounted");
    const userListener = (dbUser) => {
      console.log("dispatching user", dbUser);
       dispatch(addUserToUsers(dbUser));
    };
    window.io.socket.on('user', userListener);
    return () => {
      window.io.socket.off('user', userListener);
    }
  }, []);
  
  function gotDotResponse(data, response) {
    setLoading(false);
    console.log('Current dots: ', data?.dots);
    console.log('Current users: ', data?.users);
    setDots(data?.dots);
    dispatch(handleInitResponse(data));
  }

  useEffect(() => {
    const dotListener = function (dot) {
        setLocalDots([...localDots, dot]);
      };
    window.io.socket.on('dot', dotListener);
    return () => {
      window.io.socket.off('dot', dotListener);
    }
  }, [localDots, relisten]);
  
  useEffect(()=>{
    const onClick = event => {
      if(!canPaint) {
        return true;
      }
      const newDot = { x: event.pageX, 
        y: event.pageY, 
        id: randomstring.generate(7),
        color: paintColor,
      };
      console.log("onClick localDots", localDots);
      setLocalDots([...localDots, newDot]);
      console.log("newDot", newDot);

      window.io.socket.post(
        '/api/dot',
        { x: event.pageX, y: event.pageY, color: paintColor }, 
        (data, response) => {
          console.log(response.statusCode);
        }
      )
    }
    document.addEventListener('click', onClick);

    return () => {
      console.log("removed click listener");
      document.removeEventListener('click', onClick);
    }
  }, [localDots, canPaint, paintColor]);

  useEffect(() => {
    const disconnectListener = () => {
      console.log('sails websocket disconnect captured. Reconnecting...');
      window.io.socket.reconnect();
      setLoading(true);
      window.io.socket.get('/api/dot', gotDotResponse);
      setLocalDots([]);
      console.log("resetting local dots");
      setRelisten(true);
    }
    window.io.socket.on('disconnect', disconnectListener);
    return () => {
      console.log("Would have called of the disconnect listener");
      window.io.socket.off('disconnect', disconnectListener);
    }
  }, []);

  const renderDot = dotObject => {
    const offset = 15;
    return <div className='dot' key={dotObject.id} style={{left: dotObject.x - offset, top: dotObject.y - offset, backgroundColor: dotObject.color}}></div>
  }

  const viewWidth = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    return vw;
  }

  const viewHeight = () => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    return vh;
  }

  const rightBarClick = event => {
    //event.stopPropagation();
    const rightestDot = getRightestDot() || { x: 0 };
    setRightFencePost(getFencePost(rightestDot));
  }
  
  const downBarClick = event => {
    //event.stopPropagation();
    const downestDot = getDownestDot() || { y: 0 };
    const downFencePost = getDownFencePost(downestDot);
    console.log("downFencePost", downFencePost);
    setDownFencePost(downFencePost);
  }

  const getJoinedDots = () => {
    return [...dots, ...localDots];
  }

  const getRightestDot = () => {
    const sortedDots = getJoinedDots().sort((a, b) => {
      if(a.x == b.x){
        return 0;
      }
      if(a.x > b.x) {
        return -1;
      } else {
        return 1;
      }
    });
    return sortedDots[0];
  }
  
  const getDownestDot = () => {
    const sortedDots = getJoinedDots().sort((a, b) => {
      if(a.y == b.y){
        return 0;
      }
      if(a.y > b.y) {
        return -1;
      } else {
        return 1;
      }
    });
    return sortedDots[0];
  }

  const getFencePost = (rightestDot) => {
      const myWidth = rightestDot.x + 100 + (viewWidth());
      return myWidth;
  }
  
  const getDownFencePost = (downestDot) => {
      const myHeight = downestDot.y + 100 + (viewHeight());
      return myHeight;
  }

  const appStyles = () => {
    let myWidth = viewWidth();
    let myHeight = viewHeight();
    let rightestDot = getRightestDot();
    let downestDot = getDownestDot();
    if(rightestDot != undefined) {
      const rightestDotX = rightestDot.x + 100;
      myWidth = Math.max(rightestDotX, rightFencePost || 0);
    }
    if(downestDot != undefined) {
      const downestDotY = downestDot.y + 100;
      myHeight = Math.max(downestDotY, downFencePost || 0);
    }
    return {
      width: myWidth,
      height: myHeight,
    };
  }

  return (
    <div className="App" style={appStyles()}>
      {loading && <div className='loading'>loading...</div>}
      <div className='goRightSpacer'></div>
     {dots.map((dotObject => renderDot(dotObject)))} 
     {localDots.map((dotObject => renderDot(dotObject)))}
     <Mouse relisten={relisten} />
     <Menu rightBarClick={rightBarClick} downBarClick={downBarClick} />
    </div>
  );
}

export default App;
