import { useState } from "react";
import { AiFillSetting, 
    AiOutlineBorderRight, 
    AiOutlineBorderBottom } from "react-icons/ai";
import "./Menu.css";

import { useDispatch } from "react-redux";
import { setCanPaint, setPaintColor } from "./features/draw/drawSlice";
import { useSpring, animated } from 'react-spring';

import { ChromePicker } from 'react-color';

function Menu(props) {
    
    const [username, setUsername] = useState('');
    const [color, setColor] = useState('#000000');
    const dispatch = useDispatch();
    const [hide, setHide] = useState(true);
    const styles = useSpring({ 
        opacity: hide ? 0 : 1, 
        top: hide ? -1000 : 80 
    });
  
    const onUsernameChange = event => {
      setUsername(event.target.value);
    }
  
    const onUsernameSubmit = event => {
      event.preventDefault();
      console.log("Submitting ...", username);
      setHide(true);
      window.io.socket.post(
          '/api/user',
          { username }, 
          (data, response) => {
            console.log('updated user', response.statusCode);
          }
        )
    }
    const handleMenuClick = event => {
        event.stopPropagation();
    }
    const settingsOnEnter = event => {
        console.log("mouse on enter")
        dispatch(setCanPaint(false))
    }
    const settingsOnLeave = event => {
        console.log("mouse on leave");
        dispatch(setCanPaint(true));
    }

    const settingsOnClick = event => {
        setHide(!hide);
    }

    const canvasRightClick = event => {
        props.rightBarClick(event);
        setHide(true);
    }

    const canvasBottomClick = event => {
        props.downBarClick(event);
        setHide(true);
    }

    const handleChangeComplete = (color) => {
        setColor(color);
        dispatch(setPaintColor(color.hex));
    }

    const handleChangeColor = (color, event) => {
        setColor(color);
        dispatch(setPaintColor(color.hex));
    }

    return (
        <>
         <AiFillSetting className="menuIcon" onClick={settingsOnClick} onMouseEnter={settingsOnEnter} onMouseLeave={settingsOnLeave} />
         <animated.div  className="menuContainer" onClick={handleMenuClick} style={styles}>
            <h1>WWPA</h1>
                <p>Choose a username</p>
            <form onSubmit={onUsernameSubmit}>
                <input type="text" placeholder="Username" value={username} onChange={onUsernameChange} />
                <button onClick={onUsernameSubmit}>Submit</button>
            </form>
            <div className="canvasIcons">
                <p>Extend the canvas size</p>
                <AiOutlineBorderBottom className="canvasBottom" onClick={canvasBottomClick}  />
                <AiOutlineBorderRight className="canvasRight" onClick={canvasRightClick} />
            </div>
            <div className="colorPicker">
                <p>Choose a color</p>
                <ChromePicker disableAlpha={true} color={color} onChange={handleChangeColor} onChangeComplete={handleChangeComplete} />
            </div>
         </animated.div>
        </>
    )
}

export default Menu;