import './App.css';
import { useState, useEffect, useRef } from 'react';
import './Mouse.css';
//import { useTransition, animated, config } from 'react-spring';
//import { useSprings, animated, config } from 'react-spring';
import { useSelector, useDispatch } from 'react-redux';
import { 
  selectMouse,
  addMouseToMice, 
  selectMice,
  selectUsers
} from './features/draw/drawSlice';

function Mouse(props) {
  const mouse = useSelector(selectMouse);
  const mice = useSelector(selectMice);
  const users = useSelector(selectUsers);

  const dispatch = useDispatch();
  
  const mouseX = useRef(0);
  const mouseY = useRef(0);

  const [myMouse, setMyMouse] = useState({x: 0, y: 0});
  const [loading, setLoading] = useState(undefined);

  /*
  const transitions = useTransition(mice, {
    config: { ...config.stiff, duration: 100 },
    from: {
      position: 'absolute', left: 0, top: 0,
    },
    enter: item => [
      { left: item.x, top: item.y },
    ],
    leave: item => [
      { left: item.x, top: item.y },
    ],
    update: item => [
      { left: item.x, top: item.y },
    ],
    keys: item => item.key,
    delay: 0,
  });
  */

  /*
  const springs = useSprings(
    1,
    mice.map(item => ({ left: item.x, top: item.y }))
  );
  */

  const mouseListener = (dbMouse) => {
      dispatch(addMouseToMice(dbMouse));
  };

  const onConnect = () => {
    console.log("On connect!");
    window.io.socket.on('mouse', mouseListener);
  }

  useEffect(() => {
    window.io.socket.on('connect', onConnect);
    return () => {
      window.io.socket.off('connect', onConnect);
    }
  }, []);

  useEffect(()=>{
    console.log("mouse mounted");
      const timer = setInterval(()=>{
        if(mouse?.id == undefined) {
          console.log("mouse not defined yet");
          return;
        }
        const url = `/api/mouse/${mouse.id}`;
        console.log("posting mouse", url);
        window.io.socket.post(
          url,
          { x: mouseX.current, y: mouseY.current }, 
          (data, response) => {
          }
        )
    }, 100);

    return () => {
      clearInterval(timer);
    }
  }, [mouse]);
  
  useEffect(()=>{
    const onMouseMove = event => {
      mouseX.current = event.pageX;
      mouseY.current = event.pageY;
    }
    document.addEventListener('mousemove', onMouseMove);

    return () => {
      console.log("removed mousemove listener");
      document.removeEventListener('mousemove', onMouseMove);
    }
  }, []);

  const usernameFromId = (userId) => {
    if(!Array.isArray(users)) {
      return '';
    }
    const user = users.find(element => element.id == userId);
    return user.username;
  };

  return (
    <>
    {mice.map(item => {
      return <div className='mouseCursor' key={item.id} style={{left: item.x, top: item.y}}><img className='mouseCursorImage' src="mouse-stroke.png" /><div className='mouseCursorBar'><span>{usernameFromId(item.user)}</span></div></div>
    })};
    </>
  );

  /*
  return (
    <>
    {transitions((props, item) => (
        <animated.div
          className='mouseCursor'
          key={item.id}
          style={props}>
        </animated.div>
      ))}
    </>
  );
  */
  
 /*
 return (
  <>
  {springs.map((styles) => <animated.div className='mouseCursor' style={styles} />)}
  </>
 )
 */
}

export default Mouse;
